/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Početna"}>
        <SiteHeader />

        <Column className="css-1pngv59 css-42e4bw --parallax mt--30 pb--0 pt--0" name={"intro-3"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/31729/a9a6e59c06bd41c6bf43eef531793e42_e=692x12x2330x1311_bl=3_bri=99_con=90_ove=ffffffx15__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31729/a9a6e59c06bd41c6bf43eef531793e42_e=692x12x2330x1311_bl=3_bri=99_con=90_ove=ffffffx15__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/31729/a9a6e59c06bd41c6bf43eef531793e42_e=692x12x2330x1311_bl=3_bri=99_con=90_ove=ffffffx15__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31729/a9a6e59c06bd41c6bf43eef531793e42_e=692x12x2330x1311_bl=3_bri=99_con=90_ove=ffffffx15__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/31729/a9a6e59c06bd41c6bf43eef531793e42_e=692x12x2330x1311_bl=3_bri=99_con=90_ove=ffffffx15__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/31729/a9a6e59c06bd41c6bf43eef531793e42_e=692x12x2330x1311_bl=3_bri=99_con=90_ove=ffffffx15__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31729/a9a6e59c06bd41c6bf43eef531793e42_e=692x12x2330x1311_bl=3_bri=99_con=90_ove=ffffffx15__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31729/a9a6e59c06bd41c6bf43eef531793e42_e=692x12x2330x1311_bl=3_bri=99_con=90_ove=ffffffx15__s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --shape4 --center el--1 --full pb--0 pl--0 pr--0 pt--0 flex--center" anim={null} animS={null} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3 --center" anim={"5"} animS={"3"} style={{"maxWidth":1162,"paddingLeft":0}}>
              
              <Title className="title-box fs--36 title-box--invert" content={"<br><br><span style=\"color: rgb(19, 19, 211);\">Trebate čisto?<br>&nbsp;Zašto onda ne BAŠ ČISTO<span style=\"font-weight: bold;\">!</span></span>"}>
              </Title>

              <Text className="text-box fs--22 text-box--invert pl--06" content={"<span style=\"color: rgb(19, 19, 211);\">Servis sa dugogodišnjim iskustvom u čišćenju.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0" anim={null} animS={null} style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--40" name={"information"}>
          
          <ColumnWrap className="column__flex --justify el--3 mb--20 mt--10 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --left" anim={"2"} animS={"3"} style={{"maxWidth":410}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":1000}} content={"<span style=\"color: var(--black);\">Svi volimo čiste i mirisne prostore gdje&nbsp; živimo, stanujemo, radimo.<br>Nažalost zbog posla i mnogih obaveza za čišćenje ostaje sve manje vremena.</span><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --left" anim={"2"} animS={"3"} style={{"maxWidth":410}}>
              
              <Text className="text-box" content={"<span style=\"color: var(--color-supplementary);\">Zapuštenost i ne čišćenje životnog prostora ubrzo stvara&nbsp; nelagodu, stres i čak financijsku štetu .</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --left" anim={"2"} animS={"3"} style={{"maxWidth":410}}>
              
              <Text className="text-box" content={"<span style=\"color: var(--color-supplementary);\">Mnogi su nam povjerili brigu o čistoći. Kod odlaska smo uvijek čuli: <span style=\"font-weight: bold;\">\"Sada je baš čisto\"</span>.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--16" name={"oujo4ky8lad"}>
          
          <ColumnWrap className="column__flex js-anim  --anim5 --center el--1" anim={"5"} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(19, 19, 211);\">Usluge koje nudimo</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"hqmd7twxvdr"} layout={"l4"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--3" anim={"2"} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_s=350x_.jpg 350w, https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(19, 19, 211);\">USLUGE GENERALNOG ČIŠĆENJA</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">Potpuno čišćenje doma, izuzev pećnice i frižidera koji su zasebni elementi ,Kao takvi zasebno se naplaćuju.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31729/0f4f245a33d8478fbb26c7a1d5ca02a2_e=0x60x500x281_s=350x_.jpg"} sizes={"100vw"} style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/31729/0f4f245a33d8478fbb26c7a1d5ca02a2_e=0x60x500x281_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(19, 19, 211);\">USLUGE ODRŽAVANJA ČISTOĆE</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">Redovno čišćenje prostora u najduljem razmaku 10 dana od posljednjeg čišćenja.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31729/ef7f60cc44f5446cbf1f7fbda6330f2f_e=0x0x870x489_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/31729/ef7f60cc44f5446cbf1f7fbda6330f2f_e=0x0x870x489_s=350x_.jpg 350w, https://cdn.swbpg.com/t/31729/ef7f60cc44f5446cbf1f7fbda6330f2f_e=0x0x870x489_s=660x_.jpg 660w, https://cdn.swbpg.com/t/31729/ef7f60cc44f5446cbf1f7fbda6330f2f_e=0x0x870x489_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(19, 19, 211);\">USLUGE DUBINSKOG ČIŠĆENJA NAMJEŠTAJA</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">Ovom uslugom riješite se mrlja , mirisa i neuglednog izgleda svoga tapiciranog namještaja.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"hqmd7twxvdr"} style={{"paddingLeft":0}} layout={"l4"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--2 flex--top" anim={"2"} columns={"2"}>
            
            <ColumnWrapper style={{"paddingRight":0}}>
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31729/e12b850592a248a0bccd9037de45f4f6_e=0x0x1023x576_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/31729/e12b850592a248a0bccd9037de45f4f6_e=0x0x1023x576_s=350x_.jpg 350w, https://cdn.swbpg.com/t/31729/e12b850592a248a0bccd9037de45f4f6_e=0x0x1023x576_s=660x_.jpg 660w, https://cdn.swbpg.com/t/31729/e12b850592a248a0bccd9037de45f4f6_e=0x0x1023x576_s=860x_.jpg 860w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(19, 19, 211);\">ČIŠĆENJE UREDA I POSLOVNIH PROSTORA</span>"}>
              </Text>

              <Text className="text-box" style={{"paddingLeft":0,"paddingRight":0}} content={"<span style=\"color: var(--black);\">Čišćenje radnog prostora za što ugodniju radnu atmosferu.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31729/1edcebbfd4544e8aab18b6308e129881_e=0x0x760x428_s=660x_.jpeg"} sizes={"100vw"} style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/31729/1edcebbfd4544e8aab18b6308e129881_e=0x0x760x428_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/31729/1edcebbfd4544e8aab18b6308e129881_e=0x0x760x428_s=660x_.jpeg 660w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(19, 19, 211);\">ČIŠĆENJE NAKON GRAĐEVINSKIH I LIČILAČKIH RADOVA</span><br>"}>
              </Text>

              <Text className="text-box" style={{"paddingLeft":29,"paddingRight":36}} content={"<span style=\"color: var(--black);\">Useljavanje u novi stan , ili nakon adaptacijskih radova iziskuje posebnu vrstu čišćenja. Upravo ova vrsta čišćenja vam je neophodna u tim situacijama.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-j2j4zq css-42e4bw --parallax" name={"footer"} style={{"paddingBottom":40}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=3_ove=000000x15__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=3_ove=000000x15__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=3_ove=000000x15__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=3_ove=000000x15__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=3_ove=000000x15__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=3_ove=000000x15__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=3_ove=000000x15__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/31729/7b7688225c1d4da99fe33139ce87e137_bl=3_ove=000000x15__s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --right el--1 mb--20 flex--center" style={{"maxWidth":1400}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":"","marginTop":29}} content={"<a href=\"/contact\" style=\"color: var(--white);\">Kontaktirajte nas</a>"}>
              </Title>

              <Text className="text-box fs--20 text-box--invert" style={{"marginTop":22}} content={"<span style=\"font-weight: bold; color: var(--white);\"><a href=\"tel:0989188334\" style=\"\">0989188334</a></span><br>"}>
              </Text>

              <Text className="text-box text-box--invert" content={"<a href=\"mailto:bascisto@gmail.cm\" target=\"_blank\" style=\"font-weight: bold; color: var(--white);\">bascisto@gmail.com</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}